import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { getLMSCourses, courseSelect, sessionSelect, eventSelect } from "../../Redux/Actions/coursesAction";
import axios from "axios";
import CourseOfferingActivityModal from "./courseOfferingActivityModal";
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../../config';
import Loader from "./../../components/Loader";

function OraclelearnScheduledTraining() {
  const [courseLoading, setCourseLoading] = useState(true);
  const [sessionLoading, setSessionLoading] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [coadata, setCoadata] = useState([]);
  const [course, setCourse] = useState({});
  const [sessions, setSessions] = useState([]);
  const [session, setSession] = useState({});
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState({});
  const [disableConfirm, setDisableConfirm] = useState(true);

  const navigate= useNavigate();

  // this baseApiUrl needs to be a constant and set globally
  //let baseApiUrl = "http://rldoraclelearn.localhost:8080/v1";
  let baseApiUrl = global.config.baseApiUrl;
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { courses: currentCourses, course: currentCourse, session: currentSession, event: currentEvent } = useSelector(state => state.courses);
  const { user: currentUser } = useSelector(state => state.auth);
  const dispatch = useDispatch(); 

  const useHasChanged= (val) => {
    const prevVal = usePrevious(val)
    return prevVal !== val
  }
  
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  
  useEffect(() => {
    setDisableConfirm(true);

    //sessionStorage.setItem('realtimeEnrollmentsData', []);

    return () => {
      //unmounted = true;
    };
  }, []);

  const hasCourseChanged = useHasChanged(course);
  const hasSessionChanged = useHasChanged(session);
  
  // Get Courses
  useEffect(() => {
    let unmounted = false;

    async function getOraclelearnCourses() {
      var failed = false;
      let data = sessionStorage.getItem('courses_offerings_activities');
      console.log('courses_offerings_activities: ' + JSON.stringify(data));

      if (!data) {
        let url = baseApiUrl + "/courses?name=course_offering_activity";
        console.log('getOraclelearnCourses: url: ' + url);

        let reqBody = {"instructorNumber": currentUser.usernumber};

        data = await axios.post(url, reqBody);
        console.log('The Courses are: ' + JSON.stringify(data));
        sessionStorage.setItem('courses_offerings_activities', JSON.stringify(data));
      } else {
        data = JSON.parse(data);
      }

      setCoadata(data);
      dispatch(getLMSCourses(data, failed));

      const courses_initial = [{ label: "Select a ILT Course", value: "Dummy",
                                 selectedCourse: {} }];
      var courses = data.data.courses.map(({ courseId, courseNumber, courseTitle, courseDescription }) => ({ label: courseNumber + " - " + courseTitle, 
                          value: courseNumber,
                          selectedCourse: {"course_id": courseId,
                                           "course_number": courseNumber, 
                                           "course_name": courseTitle,
                                           "courseDescription": courseDescription
                                          } 
                          }))
      const courses_array = [...courses_initial, ...courses];
      console.log("courses array: " + JSON.stringify(courses_array));
      
      if (!unmounted) {
        setCourses(courses_array);
        setCourseLoading(false);
      }
    
      localStorage.removeItem("currentCourse");
      localStorage.removeItem("currentSession");
      localStorage.removeItem("currentEvent");

      setDisableConfirm(true);

      setSessions([]);
      setEvents([]);

      if (sessions) {
        setSessionLoading(true);
      }

      if (events) {
        setEventLoading(true);
      }

    }

    getOraclelearnCourses();
  
    return () => {
      unmounted = true;
    };
  }, []);

// Get Sessions - Events
useEffect(() => {
  let unmounted = false;

  async function getOraclelearnSessions() {
    var failed = false;

    console.log('getOraclelearnSessions: currentUser: ' + JSON.stringify(currentUser));
    console.log('getOraclelearnSessions: currentCourse: ' + JSON.stringify(currentCourse));
    console.log('getOraclelearnSessions: selectedCourse: ' + JSON.stringify(course));

    let courseNumber = "";
    let courseId = "";
    
    if (currentCourse && course && Object.keys(currentCourse).length > 0) {
      courseNumber = currentCourse.course_number;
      courseId = currentCourse.course_id;

      let sessions = [];
      
      console.log('coadata: ' + JSON.stringify(coadata));
      const sessions_initial = [{ label: "Select a Classroom Offering", value: "Dummy", selectedSession: {} }];
      if (courseNumber || courseId) {
        if (coadata && coadata.data && coadata.data.offerings) {
          let offerings_info = coadata.data.offerings;
          console.log('offerings_info: ' + JSON.stringify(offerings_info));
          let offerings_info_filtered = offerings_info.filter(function (el) {
            return el.courseId === courseId &&
                   el.courseNumber === courseNumber;
          });
          console.log('offerings_info_filtered: ' + JSON.stringify(offerings_info_filtered));
          sessions = offerings_info_filtered.map(({ offeringId, offeringNumber, offeringTitle, offeringDescription, minCapacity, maxCapacity, waitListEnabled, offeringStartDate, offeringEndDate }) => ({ label: offeringNumber + " - " + offeringTitle, 
          value: offeringNumber,
          selectedSession: {"offering_id": offeringId, 
                         "offering_number": offeringNumber,
                         "offering_name": offeringTitle,
                         "offering_description": offeringDescription,
                         "offering_mincapacity": minCapacity,
                         "offering_maxcapacity": maxCapacity,
                         "offering_waitlist": waitListEnabled,
                         "offering_start_date": offeringStartDate,
                         "offering_end_date": offeringEndDate
                        } 
                    }))
        }
      }

      const sessions_array = [...sessions_initial, ...sessions];
      console.log("sessions_array: " + JSON.stringify(sessions_array));

      setEvents([]);
      if (!unmounted) {
        console.log("sessions unmounted: " + unmounted);
        setSessions(sessions_array);
        if (sessions_array && sessions_array.length > 1) {
          console.log("sessions mounted!!!");
          setSessionLoading(false);
          setEventLoading(true);
        } else {
          setSessionLoading(true);
        }
      }

  }

  setDisableConfirm(true);

  

  //if (events) {
    //setEventLoading(true);
  //}

}

if (hasCourseChanged) {
  getOraclelearnSessions();
}


return () => {
  unmounted = true;
};
}, [course]);

useEffect(() => {
  let unmounted = false;

  async function getOraclelearnEvents() {
    var failed = false;

    let data = coadata;
    console.log('getOraclelearnSessions: currentSession: ' + JSON.stringify(currentSession));
    
    if (currentSession) {
      let offeringId = currentSession.offering_id;
      let offeringNumber = currentSession.offering_number;

      console.log('The Events are: ' + JSON.stringify(data));
      console.log('The offeringId: ' + offeringId);
      console.log('The offeringNumber: ' + offeringNumber);

      const events_initial = [{ label: "Select a Classroom Activity", value: "Dummy", selectedEvent: {} }];
      let events = [];

      if (coadata && coadata.data && coadata.data.activities) {
        let activities_info = coadata.data.activities;
          console.log('activities_info: ' + JSON.stringify(activities_info));
          let activities_info_filtered = activities_info.filter(function (el) {
            return el.offeringId === offeringId &&
                   el.offeringNumber === offeringNumber;
          });
          console.log('activities_info_filtered: ' + JSON.stringify(activities_info_filtered));

        events = activities_info_filtered.map(({ activityId, activityNumber, offeringNumber, activityTitle, 
        activityDescription, activityDate, activityStartTime, activityEndTime, activityTimeZone, primaryClassroom }) => ({ label: activityNumber + " - " + activityTitle + " - " + activityDate + " - " + 
        activityStartTime + " - " + activityEndTime + " - " + activityTimeZone + " - " + primaryClassroom, 
                value: activityNumber,
                selectedEvent: {
                    "activity_id": activityId,
                    "activity_number": activityNumber,
                    "activity_name": activityTitle,
                    "activity_date": activityDate,
                    "activity_time": activityStartTime + ' - ' + activityEndTime + ' - ' + activityTimeZone,
                    "activity_venue": primaryClassroom,
                } 
              }))
      }
      const events_array = [...events_initial, ...events];
      console.log("events_array: " + JSON.stringify(events_array));

      if (!unmounted) {
        console.log("events unmounted: " + unmounted);
        setEvents(events_array);
        if (events_array && events_array.length > 1) {
          console.log("events mounted!!!");
          setEventLoading(false);
        } else {
          setEventLoading(true);
        }
      }
    }
    setDisableConfirm(true);
  }

  if (hasSessionChanged) {
    getOraclelearnEvents();
  }

  return () => {
    unmounted = true;
  };
}, [session]);

const handleCourseChange = (e) => {
  console.log('courses: ' + JSON.stringify(courses));
  console.log('course selected: ' + e.currentTarget.value);
  var selectedCourse = courses.filter(course=>course.value==e.currentTarget.value);
  console.log('selectedCourse: ' + JSON.stringify(selectedCourse));
  console.log('selectedCourse: ' + JSON.stringify(selectedCourse[0]));
  dispatch(courseSelect(selectedCourse[0].selectedCourse));
  console.log('course: ' + JSON.stringify(selectedCourse[0]));
  setCourse(e.currentTarget.value);
  setSession({});
  setEvent({});
  console.log('Course Number: ' + JSON.stringify(selectedCourse[0].value));
  if (course) {
    setDisableConfirm(false);
  } else {
    setDisableConfirm(true);
  }
}


const handleSessionChange = (e) => {
  console.log('sessions: ' + JSON.stringify(sessions));
  var selectedSession = sessions.filter(session=>session.value==e.currentTarget.value);
  dispatch(sessionSelect(selectedSession[0].selectedSession));
  console.log('session: ' + JSON.stringify(selectedSession[0]));
  //setSession(JSON.stringify(selectedSession[0].value));
  setSession(e.currentTarget.value);
  setEvent({});
  console.log('Session Number: ' + JSON.stringify(selectedSession[0].value));
  if (session && course) {
    setDisableConfirm(false);
  } else {
    setDisableConfirm(true);
  }
}

const handleEventChange = (e) => {
  console.log('events: ' + JSON.stringify(events));
  var selectedEvent = events.filter(event=>event.value==e.currentTarget.value);
  dispatch(eventSelect(selectedEvent[0].selectedEvent));
  setEvent(selectedEvent[0].value);
  console.log('Event Number: ' + JSON.stringify(selectedEvent[0].value));

  if (event && session && course) {
    setDisableConfirm(false);
  } else {
    setDisableConfirm(true);
  }
}

const onCourseSessionEventFormSubmit = (e) => {
  e.preventDefault();
  handleClose();
};

const handleSubmit = (e) => {
  console.log("Inside handleSubmit");
  e.preventDefault();
 // history.push('/manageRoster');
 navigate("/manageOfferingActivityRoster", {replace:true});
 // return <Navigate to="/manageRoster" replace={true}/>;
}

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled ? 'red' : 'blue',
      color: '#FFF',
      cursor: isDisabled ? 'not-allowed' : 'default'
    };
  },
};

  return (
    <>
      {courseLoading ? (
                <div
                    style={{
                        width: "80px",
                        margin: "auto",
                    }}
                >
                    <Loader />
                </div>
            ) : (
              <div className="col-md-12">
              <div className="card card-container">
                  <label><strong>Select Course:</strong></label>
                  
                    <select
                      disabled={courseLoading}
                      value={course}
                      onChange={(e) => handleCourseChange(e)}
                      styles={colourStyles}
                    >
                    {courses.map(({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                    </select>
                  
                  <br />
                  
                  <label><strong>Select Offering:</strong></label>
                  <select
                    disabled={sessionLoading}
                    value={session}
                    onChange={(e) => handleSessionChange(e)}
                  >
                  {sessions.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                  ))}
                  </select>
                  <br />
                  <label><strong>Select Activity:</strong></label>
                  <select
                    disabled={eventLoading}
                    value={event}
                    onChange={(e) => handleEventChange(e)}
                  >
                  {events.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                  ))}
                  </select>
                </div>
              </div>
        )
      }
      

    <br />
    <br />

      <div className="d-flex align-items-center justify-content-center">
          <Button variant="primary" onClick={handleShow} disabled={disableConfirm}>
            Confirm
          </Button>
      </div>
      
    <Modal show={show} onHide={handleClose} dialogClassName="user-modal">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Selection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CourseOfferingActivityModal onSubmit={onCourseSessionEventFormSubmit} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
    </Modal>

  </>
  );
}

export default OraclelearnScheduledTraining;