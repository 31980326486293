import {
    GET_ENROLLMENTS_SUCCESS,
    GET_ENROLLMENTS_FAIL,
    SELECT_USER_SUCCESS,
    SELECT_USER_FAIL,
    SELECT_USERS_SUCCESS,
    SELECT_USERS_FAIL
} from "../Types/types";

export const getLMSEnrollments = (data, failed) => (dispatch) => {
    console.log("getLMSEnrollments: RESPONSE:" + JSON.stringify(data));
    if (failed) {
        dispatch({
            type: GET_ENROLLMENTS_FAIL,
        })
    } else {
        dispatch({
            type: GET_ENROLLMENTS_SUCCESS,
            payload: { enrollments: data },
        });
        //sessionStorage.setItem('realtimeEnrollmentsData', JSON.stringify(data.enrollments_from_rt));
    }

    return data;
};

export const selectUser = (data, failed) => (dispatch) => {
    console.log("selectUser: RESPONSE:" + JSON.stringify(data));
    if (failed) {
        dispatch({
            type: SELECT_USER_FAIL,
        })
    } else {
        dispatch({
            type: SELECT_USER_SUCCESS,
            payload: { user: data },
        });
    }

    return data;
};

export const selectUsers = (data, failed) => (dispatch) => {
    console.log("selectUsers: RESPONSE:" + JSON.stringify(data));
    if (failed) {
        dispatch({
            type: SELECT_USERS_FAIL,
        })
    } else {
        dispatch({
            type: SELECT_USERS_SUCCESS,
            payload: { users: data },
        });
    }

    return data;
};